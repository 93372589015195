<template>
  <div class="evaluate-home wrapper">
    <el-tabs v-model="activeName">
      <el-tab-pane label="活动报告" name="first">
        <div class="activity-home">
          <div class="table_section">
            <el-table :data="tableData" border>
              <el-table-column prop="number" label="序号" width="80" align="center" type="index">
              </el-table-column>
              <el-table-column prop="name" label="报告名称" width="420" align="center">
                <!-- <template slot-scope="scope">
                  {{scope.row.name}}
                </template> -->
              </el-table-column>
              <el-table-column prop="date" label="日期" width="180" align="center">
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="150" align="center">
                <template slot-scope="scope">
                  <el-button @click="toReportDetail(scope.row)" type="text" size="small">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
            <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
              :limit.sync="pages.pageSize" :pageSizes="[3,6,9]" @pagination="handlePageChange">
            </pagination>
          </div>
        </div>

      </el-tab-pane>
      <el-tab-pane></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  export default {
    inject: ['reload'],
    data() {
      return {
        tableData: [{
            name: '"互联网+阅读生态"共读项目 —— 初赛',
            date: '2020.12.01',
            id: 5,
          },
          {
            name: '"互联网+阅读生态"共读项目 —— 复赛',
            date: '2020.12.01',
            id: 6,
          }
        ],
        pages: { //分页
          currentPage: 1,
          pageSize: 10,
          total: 1,
        },
        activeName: 'first', //当前项目
      };
    },
    computed: {},
    methods: {
      // 翻页
      handlePageChange(data) {
        this.pages.currentPage = data.pageIndex
        this.pages.pageSize = data.pageSize
      },
      // 查看点击
      toReportDetail(data) {
        this.$router.push({
          path: `/leader/report/${data.id}`,
          query: {
            title: data.name,
            breadNumber: 4,
          }
        })

      }
    },
    mounted() {},
    activated() {}
  };
</script>

<style lang="less" scoped>
  @import "../manage/less/table.less";

  .evaluate-home {
    box-sizing: border-box;
    margin-bottom: 100px;
    background-color: #fff;
  }
</style>